import React from 'react'
import { motion, AnimatePresence } from "framer-motion"

const ElectricalService = () => {
  return (
    <AnimatePresence>     
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1  }}
      exit={{ opacity: 0}}
      transition={{ duration: 2 }}
      className='bg-black mx-auto py-[5px]'>
      <section className=' h-[600px] flex items-center bg-cover bg-no-repeat bg-center bg-[url("/public/assets/img-pages/electrical/motorElectrico.jpg")]
       mx-[0.5rem] rounded-t-lg'>
        <div className='text-center backdrop-blur-[3px] backdrop-brightness-50 w-[350px] px-[20px] py-[3rem] bg-black/50
        rounded-[20px] shadow-md  mx-auto
        sm:w-[500px]
        md:w-[700px]
        lg:w-[1000px] lg:mx-auto'>
          <p className='font-[Roboto] font-[700] text-[#feba12] text-[25px] leading-[30px]
          md:text-[50px] md:leading-[65px]
          sm:text-[34px] sm:leading-[45px]'>
            MANTENIMIENTO DE MOTORES ELÉCTRICOS
          </p>
          <p className='font-[Poppins] mx-auto max-w-[900px] mt-[15px] text-white text-[14px] mb-[30px] 
          md:mt-[20px] md:mb-[50px] md:text-[22px]'>
            Brindamos el servicio de mantenimiento de motores eléctricos: inspección, detección de vibraciones, limpieza y lubricación, megado, mantenimiento correctivo, etc.
          </p>
          <a className='mt-[40px]  md:mt-[40px]' href='https://api.whatsapp.com/send?phone=51972198805&text=Hola.%20Cu%C3%A9ntame%20sobre%20tus%20servicios.%20%F0%9F%98%8A'  target="_blank">
            <button type="button" className="bg-[#050708] hover:bg-[#050708]/70 transition-all backdrop-brightness-[0.01]
            font-medium rounded-lg px-10 py-2  shadow-[0px_0px_15px_white]
            text-center inline-flex items-center  mr-2 mb-2">
              <svg  className=''
                width="23" height="23" viewBox="0 0 308 308" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M227.905 176.981C227.305 176.693 204.851 165.636 200.861 164.2C199.232 163.615 197.487 163.044 195.631 163.044C192.599 163.044 190.052 164.555 188.068 167.523C185.825 170.857 179.035 178.794 176.937 181.165C176.663 181.478 176.289 181.852 176.065 181.852C175.864 181.852 172.389 180.421 171.337 179.964C147.25 169.501 128.967 144.34 126.46 140.097C126.102 139.487 126.087 139.21 126.084 139.21C126.172 138.887 126.982 138.075 127.4 137.656C128.623 136.446 129.948 134.851 131.23 133.308C131.837 132.577 132.445 131.845 133.042 131.155C134.902 128.991 135.73 127.311 136.69 125.365L137.193 124.354C139.537 119.697 137.535 115.767 136.888 114.498C136.357 113.436 126.876 90.5544 125.868 88.1504C123.444 82.3494 120.241 79.6484 115.79 79.6484C115.377 79.6484 115.79 79.6484 114.058 79.7214C111.949 79.8104 100.464 81.3224 95.3856 84.5234C90.0006 87.9184 80.8906 98.7404 80.8906 117.772C80.8906 134.901 91.7606 151.074 96.4276 157.225C96.5436 157.38 96.7566 157.695 97.0656 158.147C114.939 184.249 137.22 203.593 159.807 212.616C181.552 221.302 191.849 222.306 197.703 222.306C197.704 222.306 197.704 222.306 197.704 222.306C200.164 222.306 202.133 222.113 203.87 221.942L204.972 221.837C212.484 221.171 228.992 212.617 232.747 202.182C235.705 193.963 236.485 184.983 234.517 181.724C233.169 179.508 230.846 178.393 227.905 176.981Z" 
                fill="#00bb2d"/>
                <path d="M156.734 0C73.3178 0 5.45377 67.354 5.45377 150.143C5.45377 176.92 12.6198 203.131 26.1948 226.071L0.211768 302.716C-0.272232 304.145 0.0877677 305.725 1.14477 306.801C1.90777 307.58 2.94277 308 3.99977 308C4.40477 308 4.81277 307.939 5.21077 307.812L85.1308 282.416C107.001 294.101 131.719 300.269 156.735 300.269C240.143 300.27 308 232.923 308 150.143C308 67.354 240.143 0 156.734 0ZM156.734 268.994C133.195 268.994 110.396 262.197 90.7978 249.337C90.1388 248.904 89.3738 248.682 88.6038 248.682C88.1968 248.682 87.7888 248.744 87.3918 248.87L47.3568 261.596L60.2808 223.467C60.6988 222.233 60.4898 220.872 59.7198 219.82C44.7958 199.428 36.9068 175.335 36.9068 150.143C36.9068 84.6 90.6608 31.276 156.733 31.276C222.797 31.276 276.545 84.6 276.545 150.143C276.546 215.678 222.799 268.994 156.734 268.994Z" 
                fill="#00bb2d"/>
              </svg>
              <span className='font-[Roboto] text-[22px] ml-[10px] text-white'>Contáctanos</span>
            </button>
          </a>
          
        </div>
      </section>

      <section className=' h-[600px] my-[2px]  flex items-center bg-cover bg-no-repeat bg-center 
      bg-[url("/public/assets/img-pages/electrical/tableroElectrico.jpg")]
      mx-[0.5rem] '>
        <div className='text-center backdrop-blur-[3px] backdrop-brightness-50 w-[350px] px-[20px] py-[3rem] bg-black/60
        rounded-[20px] shadow-md  mx-auto 
        sm:w-[500px]
        md:w-[700px]
        lg:w-[1000px] lg:mx-auto'>
          <p className='font-[Roboto] font-[700] text-[#feba12] text-[25px] leading-[30px]
              md:text-[50px] md:leading-[65px]
              sm:text-[34px] sm:leading-[45px]'>
            MANTENIMIENTO DE TABLEROS ELÉCTRICOS          
          </p>
          <p className=' mx-auto font-[Poppins] max-w-[900px] mt-[15px] text-white text-[14px] mb-[30px] 
          md:mt-[20px] md:mb-[50px] md:text-[20px]'>
            Mantenimiento preventivo y correctivo de tableros adosados, empotrados, auto-soportados, de transferencia y control de potencia.    
          </p>
          <a className='mt-[40px]  md:mt-[40px]'>
          <a className='mt-[40px]  md:mt-[40px]' href='https://api.whatsapp.com/send?phone=51972198805&text=Hola.%20Cu%C3%A9ntame%20sobre%20tus%20servicios.%20%F0%9F%98%8A'  target="_blank">
            <button type="button" className="bg-[#050708] hover:bg-[#050708]/70 transition-all backdrop-brightness-[0.01]
            font-medium rounded-lg px-10 py-2  shadow-[0px_0px_15px_white]
            text-center inline-flex items-center  mr-2 mb-2">
              <svg  className=''
                width="23" height="23" viewBox="0 0 308 308" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M227.905 176.981C227.305 176.693 204.851 165.636 200.861 164.2C199.232 163.615 197.487 163.044 195.631 163.044C192.599 163.044 190.052 164.555 188.068 167.523C185.825 170.857 179.035 178.794 176.937 181.165C176.663 181.478 176.289 181.852 176.065 181.852C175.864 181.852 172.389 180.421 171.337 179.964C147.25 169.501 128.967 144.34 126.46 140.097C126.102 139.487 126.087 139.21 126.084 139.21C126.172 138.887 126.982 138.075 127.4 137.656C128.623 136.446 129.948 134.851 131.23 133.308C131.837 132.577 132.445 131.845 133.042 131.155C134.902 128.991 135.73 127.311 136.69 125.365L137.193 124.354C139.537 119.697 137.535 115.767 136.888 114.498C136.357 113.436 126.876 90.5544 125.868 88.1504C123.444 82.3494 120.241 79.6484 115.79 79.6484C115.377 79.6484 115.79 79.6484 114.058 79.7214C111.949 79.8104 100.464 81.3224 95.3856 84.5234C90.0006 87.9184 80.8906 98.7404 80.8906 117.772C80.8906 134.901 91.7606 151.074 96.4276 157.225C96.5436 157.38 96.7566 157.695 97.0656 158.147C114.939 184.249 137.22 203.593 159.807 212.616C181.552 221.302 191.849 222.306 197.703 222.306C197.704 222.306 197.704 222.306 197.704 222.306C200.164 222.306 202.133 222.113 203.87 221.942L204.972 221.837C212.484 221.171 228.992 212.617 232.747 202.182C235.705 193.963 236.485 184.983 234.517 181.724C233.169 179.508 230.846 178.393 227.905 176.981Z" 
                fill="#00bb2d"/>
                <path d="M156.734 0C73.3178 0 5.45377 67.354 5.45377 150.143C5.45377 176.92 12.6198 203.131 26.1948 226.071L0.211768 302.716C-0.272232 304.145 0.0877677 305.725 1.14477 306.801C1.90777 307.58 2.94277 308 3.99977 308C4.40477 308 4.81277 307.939 5.21077 307.812L85.1308 282.416C107.001 294.101 131.719 300.269 156.735 300.269C240.143 300.27 308 232.923 308 150.143C308 67.354 240.143 0 156.734 0ZM156.734 268.994C133.195 268.994 110.396 262.197 90.7978 249.337C90.1388 248.904 89.3738 248.682 88.6038 248.682C88.1968 248.682 87.7888 248.744 87.3918 248.87L47.3568 261.596L60.2808 223.467C60.6988 222.233 60.4898 220.872 59.7198 219.82C44.7958 199.428 36.9068 175.335 36.9068 150.143C36.9068 84.6 90.6608 31.276 156.733 31.276C222.797 31.276 276.545 84.6 276.545 150.143C276.546 215.678 222.799 268.994 156.734 268.994Z" 
                fill="#00bb2d"/>
              </svg>
              <span className='font-[Roboto] text-[22px] ml-[10px] text-white'>Contáctanos</span>
            </button>
          </a>
          </a>
        </div>
      </section>

      <section className=' h-[600px] flex items-center bg-cover bg-no-repeat bg-center
       bg-[url("/public/assets/img-pages/electrical/automatizacion.jpg")] 
      mx-[0.5rem] rounded-lg'>
        <div className='text-center backdrop-blur-[3px] backdrop-brightness-50 w-[350px] px-[20px] py-[3rem] bg-black/60
        rounded-[20px] shadow-md  mx-auto 
        sm:w-[500px]
        md:w-[700px]
        lg:w-[1000px] lg:mx-auto'>
          <p className='font-[Roboto] font-[800] text-[#feba12] text-[25px] leading-[30px]
          md:text-[50px] md:leading-[65px]
          sm:text-[34px] sm:leading-[45px]'>
            AUTOMATIZACIÓN DE PROCESOS INDUSTRIALES
          </p>
          <p className=' mx-auto font-[Poppins]  max-w-[900px] mt-[15px] text-white text-[14px] mb-[30px] 
          md:mt-[20px] md:mb-[50px] md:text-[20px] '>
            Incremente la productividad de sus procesos industriales y reduzca la variabilidad junto a las posibilidades que los productos se vean afectados por factores humanos.</p>
            <a className='mt-[40px]  md:mt-[40px]' href='https://api.whatsapp.com/send?phone=51972198805&text=Hola.%20Cu%C3%A9ntame%20sobre%20tus%20servicios.%20%F0%9F%98%8A'  target="_blank">
              <button type="button" className="bg-[#050708] hover:bg-[#050708]/70 transition-all backdrop-brightness-[0.01]
              font-medium rounded-lg px-10 py-2  shadow-[0px_0px_15px_white]
              text-center inline-flex items-center  mr-2 mb-2">
                <svg  className=''
                  width="23" height="23" viewBox="0 0 308 308" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M227.905 176.981C227.305 176.693 204.851 165.636 200.861 164.2C199.232 163.615 197.487 163.044 195.631 163.044C192.599 163.044 190.052 164.555 188.068 167.523C185.825 170.857 179.035 178.794 176.937 181.165C176.663 181.478 176.289 181.852 176.065 181.852C175.864 181.852 172.389 180.421 171.337 179.964C147.25 169.501 128.967 144.34 126.46 140.097C126.102 139.487 126.087 139.21 126.084 139.21C126.172 138.887 126.982 138.075 127.4 137.656C128.623 136.446 129.948 134.851 131.23 133.308C131.837 132.577 132.445 131.845 133.042 131.155C134.902 128.991 135.73 127.311 136.69 125.365L137.193 124.354C139.537 119.697 137.535 115.767 136.888 114.498C136.357 113.436 126.876 90.5544 125.868 88.1504C123.444 82.3494 120.241 79.6484 115.79 79.6484C115.377 79.6484 115.79 79.6484 114.058 79.7214C111.949 79.8104 100.464 81.3224 95.3856 84.5234C90.0006 87.9184 80.8906 98.7404 80.8906 117.772C80.8906 134.901 91.7606 151.074 96.4276 157.225C96.5436 157.38 96.7566 157.695 97.0656 158.147C114.939 184.249 137.22 203.593 159.807 212.616C181.552 221.302 191.849 222.306 197.703 222.306C197.704 222.306 197.704 222.306 197.704 222.306C200.164 222.306 202.133 222.113 203.87 221.942L204.972 221.837C212.484 221.171 228.992 212.617 232.747 202.182C235.705 193.963 236.485 184.983 234.517 181.724C233.169 179.508 230.846 178.393 227.905 176.981Z" 
                  fill="#00bb2d"/>
                  <path d="M156.734 0C73.3178 0 5.45377 67.354 5.45377 150.143C5.45377 176.92 12.6198 203.131 26.1948 226.071L0.211768 302.716C-0.272232 304.145 0.0877677 305.725 1.14477 306.801C1.90777 307.58 2.94277 308 3.99977 308C4.40477 308 4.81277 307.939 5.21077 307.812L85.1308 282.416C107.001 294.101 131.719 300.269 156.735 300.269C240.143 300.27 308 232.923 308 150.143C308 67.354 240.143 0 156.734 0ZM156.734 268.994C133.195 268.994 110.396 262.197 90.7978 249.337C90.1388 248.904 89.3738 248.682 88.6038 248.682C88.1968 248.682 87.7888 248.744 87.3918 248.87L47.3568 261.596L60.2808 223.467C60.6988 222.233 60.4898 220.872 59.7198 219.82C44.7958 199.428 36.9068 175.335 36.9068 150.143C36.9068 84.6 90.6608 31.276 156.733 31.276C222.797 31.276 276.545 84.6 276.545 150.143C276.546 215.678 222.799 268.994 156.734 268.994Z" 
                  fill="#00bb2d"/>
                </svg>
                <span className='font-[Roboto] text-[22px] ml-[10px] text-white'>Contáctanos</span>
              </button>
            </a>
          
        </div>
      </section>
  </motion.div>
  </AnimatePresence>     

  )
}

export default ElectricalService