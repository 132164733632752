import React from 'react'

const ButtonWhite = (props) => {
    return (
        <button className='w-[225px] h-[45px] text-[20px] border-2 border-white
        rounded-[10px] font-[Roboto] font-[800] brightness-125
        text-white  hover:bg-white hover:!text-black hover:font-bold
        transition-all ease-in delay-50  '>
            {props.text}
        </button>
      )
}

export default ButtonWhite