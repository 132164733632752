import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";


import Home from './pages/Home'
import MechanicService from './pages/MechanicService'
import ElectricalService from './pages/ElectricalService'
import Us from './pages/Us'
import Main from "./Main";
import ScrollTop from './hooks/ScrollTop'

function App() {
  return (
    <div className="App w-full mx-auto bg-black">
      <HashRouter >
        <ScrollTop />
          <Routes>
            <Route path="/" element={<Main />}>
              <Route index element = {<Home/>}/>
              <Route path="/ServicioMecanico" element={<MechanicService />}/>
              <Route path="/ServicioElectrico" element={<ElectricalService />}/>
              <Route path="/Nosotros" element={<Us/>}/>
            </Route> 
          </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
