import React from 'react'
import { Link,useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { useState } from 'react';

const Navbar = () => {
  const location = useLocation()
  const urlActual = location.pathname
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const [btnState,setBtnState] = useState(false)

  const styleHeader = {
    position: 'sticky',
    top: 0
  }

  const styleActive = {
    fontSize: isTabletOrMobile? "20px": "17px",
    color: "#FEBA12",
    fontWeight: "700",
  }

  const styleDisabled = {
    fontSize: isTabletOrMobile? "20px": "17px",
    color: "white",
    fontWeight: "700",
  }

  return (
    <>
    <header className='bg-black/90 z-50 shadow-md' style={styleHeader}>
      <nav className="max-w-[1800px] py-[5px] mx-auto md:px-[2.98%] md:py-0 h-[65px] md:h-[65px] ">
        <div className="container flex flex-wrap justify-between items-center h-[65px] mx-auto ">
          <Link to="/" className="flex items-center scale-[0.8] md:scale-[0.9] ">
            <img className='w-[150px]' alt='logo Zain' src='/logo/mainLogo.svg'/>
          </Link>
          <button 
            className="inline-flex items-center p-2 ml-3 rounded-lg md:hidden hover:bg-gray-900 " 
            onClick={()=>setBtnState(!btnState)}>
            <svg className="w-9 h-9" fill="#ffffff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"/>
            </svg>
            <svg className="hidden w-6 h-6" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"/>
            </svg>
          </button>
          <div 
            className="w-[100vw] pb-[15px] relative md:pb-0 md:block md:w-auto "
            style={isTabletOrMobile? {visibility: btnState ?'visible':'hidden'}: {visibility: 'visible'}}>
            <ul 
              className=" grid items-center font-[Roboto] text-[17px] h-[calc(100vh-65px)] text-center 
              md:gap-x-[8px] md:h-auto md:gap-y-[15px] md:flex 
              md:flex-row md:space-x-8 md:text-sm md:font-medium bg-black/90"
              >
              <li>
                <Link
                  onClick={()=>setBtnState(!btnState)} 
                  to="/" className={isTabletOrMobile ? '': 'navbarOption'} 
                  style={urlActual === '/' ?  styleActive : styleDisabled}>
                  Inicio
                </Link>
              </li>
              <li>
                <Link 
                  onClick={()=>setBtnState(!btnState)}
                  to="/ServicioMecanico" className='navbarOption '
                  style={urlActual === '/ServicioMecanico' ?  styleActive : styleDisabled}>
                  Servicios Mecánicos
                </Link>
              </li>
              <li>
                <Link 
                  onClick={()=>setBtnState(!btnState)}
                  to="/ServicioElectrico" className='navbarOption'
                  style={urlActual === '/ServicioElectrico' ?  styleActive : styleDisabled}>
                  Servicios Eléctricos
                </Link>
              </li>

              <li>
                <Link 
                  onClick={()=>setBtnState(!btnState)}
                  to="/Nosotros" className='navbarOption pb-[15px] md:mb-0'
                  style={urlActual === '/Nosotros' ?  styleActive : styleDisabled}>
                  Nosotros
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    </>
    
  )
}

export default Navbar