import React from 'react'

const ButtonGold = (props) => {
  return (
    <button className='transition-all !bg-none w-[200px] h-[40px] text-[18px] 
    rounded-[10px] border-goldZ font-bold text-goldZ border-solid border-[2px]
    sm:w-[225px] sm:h-[45px] sm:text-[20px]
    delay-250 ease-in-out hover:!bg-goldZ hover:!text-white'>
        {props.text}
    </button>
  )
}

export default ButtonGold