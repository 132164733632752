import React from 'react'
import { Link } from 'react-router-dom'
import ButtonGold from '../components/ButtonGold'
import ButtonWhite from '../components/ButtonWhite'
import { motion, AnimatePresence } from "framer-motion"

const Home = () => {


  return (
    <AnimatePresence>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1  }}
      exit={{ opacity: 0}}
      transition={{ duration: 2 }} 
      className='w-full mx-auto  h-full overflow-x-hidden '>
      <section 
        className=' mx-auto max-w-[1800px]  hero w-full bg-[url("/public/assets/img-pages/home/hero.jpg")] 
        bg-cover bg-no-repeat bg-center h-[700px] relative'> 
        <div 
          className='h-full mx-auto grid grid-cols-[1.1fr_0.9fr] bg-gradient-to-r to-black/[0.8] via-black/[0.5] from-black/[0.9]
          sm:bg-gradient-to-r sm:via-black/[0.93] sm:from-black sm:to-slate-700/20'>
          <div 
            className='w-[100vw] h-full flex items-center justify-start px-[8%]
            sm:pl-[8%] sm:w-full '>
            <div 
              className='text-white brightness-[1.25]
              sm:w-[620px] sm:px-0'>
              <h1 
                className='text-goldZ font-bold leading-[35px] font-[Roboto] text-[30px] text-center
                sm:text-[40px] sm:leading-[50px] sm:!text-left   ' >
                ¡UNA SOLUCIÓN PARA CADA PROBLEMA!
              </h1>
              <p 
                className='text-[18px] text-center mt-[35px] leading-[28px] font-[400] font-sans 
                sm::w-[620px] sm:text-[20px] sm:!text-left'>
                Un país que se desarrolla industrialemente, necesita de 
                profesionales que lo sostengan. Por este motivo nace ZAIN, 
                una empresa que se apoya en la ingeniería y que se adapta 
                a tus necesidades para la resolución de tus problemas.
              </p>
              <div 
                className='pt-[55px]  grid grid-rows-2 gap-y-[30px] justify-center
                sm:grid-cols-2 sm:gap-x-[150px]'>
                <a target="_blank"
                  href='https://api.whatsapp.com/send?phone=51972198805&text=Hola.%20Cu%C3%A9ntame%20sobre%20tus%20servicios.%20%F0%9F%98%8A'  
                  className=''>
                  <button 
                    className=' w-[225px] h-[45px] text-[20px] bg-goldZ border-white 
                    rounded-[10px] font-[Roboto] font-[800]
                    text-black hover:sombra
                    transition-all ease-in  ' >
                    Contáctanos
                  </button>
                </a>
                <Link to="/Nosotros"
                  className=''>
                  <ButtonWhite text="Nosotros"/>
                </Link>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </section>

      <section
        data-aos="fade-right" data-aos-duration="1000"
        data-aos-easing="ease-out-ease" 
        className='max-w-[1800px] mx-auto  py-[20px] px-[20px]'>
        <div 
          className='grid  shadow-lg shadow-black/30  rounded-[15px]
          grid-rows-[1.2fr_0.9fr] h-[550px] 
          mx-[0.5%]
          md:mx-[10%]
          lg:grid-cols-[1.1fr_0.9fr] lg:grid-rows-1 lg:h-[500px] lg:mx-0'>
          <div 
            className='bg-[url("/public/assets/img-pages/home/card-mecanico.jpg")] bg-cover 
            bg-no-repeat bg-center 
            rounded-t-[15px]
            lg:!rounded-l-[15px] lg:rounded-t-none'>
          </div>
          <div 
            className='bg-white  flex items-center border-2 justify-center shadow-lg
            rounded-b-[15px] lg:rounded-r-[15px] lg:rounded-l-none'>
            <div 
              className=' text-center font-[Roboto] max-w-[500px]  '>
              <h2 
                className='font-bold text-blackZ 
                text-[23px]
                md:text-[30px]
                xl:text-[38px]'>
                SERVICIOS MECÁNICOS
              </h2>
              <p 
                className='text-[#676767] font-[Mulish] mt-[15px] mb-[25px]  
                text-[13px] px-[5%] 
                md:text-[16px] md:px-[3%] md:mt-[25px] md:mb-[35px]
                xl:text-[18px] xl:px-0'>
                ZAIN ofrece sus servicios de especialidad mecánica en 
                estructuras metálicas, equipos oleohidráulicos, diseño 
                de máquinas y asesoría en proyectos mecánicos.            
              </p>
              <Link to="/ServicioMecanico">
                <ButtonGold text="Más información"/>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section  
        data-aos="fade-left" data-aos-duration="1000"
        data-aos-easing="ease-out-ease"
        className='max-w-[1800px] mx-auto px-[20px] pb-[20px]'>
        <div 
          className='grid  shadow-lg rounded-[15px]
          grid-rows-[1.2fr_0.9fr] h-[550px] shadow-black/30 
          mx-[0.5%]
          md:mx-[10%] sm:h-[650px]
          lg:grid-cols-[0.9fr_1.1fr] lg:grid-rows-1 lg:h-[500px] lg:mx-0'>
          <div 
            className='bg-white  flex items-center justify-center order-2 lg:!order-1 border-2
            rounded-b-[15px]
            lg:rounded-l-[15px] lg:rounded-r-none'>
            <div 
              className=' text-center font-[Roboto] max-w-[500px]'>
              <h2 
                className='font-bold text-blackZ 
                text-[23px]
                md:text-[30px]
                xl:text-[38px]'>
                SERVICIOS ELÉCTRICOS
              </h2>
              <p 
                className='text-[#676767] font-[Mulish] mt-[8px] mb-[15px]  
                text-[13px] px-[7%] 
                md:text-[16px] md:px-[3%] md:mt-[25px] md:mb-[35px]
                xl:text-[18px] xl:px-0'>
                Ofrecemos nuestros servicios para las 
                especialidades eléctricas de mantenimiento de motores 
                y tableros eléctricos, automatización de procesos 
                industriales, cableado y asesoría en 
                proyectos eléctricos.            
              </p>
              <Link to="/ServicioElectrico">
                <ButtonGold text="Más información"/>
              </Link>
            </div>
          </div>
          <div 
            className='bg-[url("/public/assets/img-pages/home/electrica.jpg")] bg-cover bg-no-repeat bg-center
            rounded-t-[15px] order-1
            lg:!rounded-r-[15px] lg:rounded-t-none lg:order-2'>
          </div>
        </div>
      </section>

      <section 
        className='w-full  text-center bg-[#feba12] pb-[50px]'>
        <div 
          className='relative  items-center py-[50px] '>
          <div 
            className=''>
            <h1 
              data-aos-easing="ease-out-ease" 
              data-aos-duration="1000"  data-aos="fade-down"
              className='font-[Roboto] font-bold tracking-tight text-black/90 text-[30px] sm:text-[45px] '>
              NUESTROS <span className=''>PRODUCTOS</span>
            </h1>
            <p 
              className=' text-[#2d2d2d] mt-[20px] mx-[10%]  font-[Mulish] text-[16px] sm:text-[18px]'>
              Ofrecemos motores eléctricos, 
              PLC's y variadores de las marcas <span className='font-bold'> Siemens, WEG y otros .</span> 
            </p>
          </div>

          <div 
            className='h-full grid grid-cols-1 justify-center gap-[35px]
            bg-[#feba12] mt-[40px] justify-items-center
            lg:grid-cols-[repeat(3,350px)]
            items-end mx-auto '>
            <div 
              className='bg-white relative  rounded-[10px]  shadow-[5px_5px_6px_rgba(184,184,184,0.25)]
              w-[280px] h-[250px] hover:scale-[1.05] hover:shadow-lg hover:shadow-black/50 transition-all 
              md:w-[354px] md:h-[349px] ease-in' >
              <img  
                alt='PLCs' src='/assets/img-pages/home/plc.jpg'
                className='w-[165px] mt-[15px]  px-[10px] mx-auto rounded-sm 
                sm:w-[235px]  sm:mt-[20px]'/>
              <div 
                className='flex flex-col items-center absolute bottom-0
                w-full  h-[65px] rounded-b-md bg-black'>
                <h4 
                  className='font-[Mulish] font-[40] text-[14px] leading-[20px] mt-[11px] text-gray-400'>
                  SIEMENS
                </h4>
                <h3 className='font-[Poppins] font-[500] text-[18px] text-white leading-[25px]'>
                  PLC's
                </h3>
              </div>
            </div>

            <div 
              className='bg-white  rounded-[10px]  shadow-[5px_5px_6px_rgba(184,184,184,0.25)]
              w-[280px] h-[250px] hover:scale-[1.05] hover:shadow-lg hover:shadow-black/50 transition-all 
              md:w-[354px] md:h-[349px] ease-in' >
              <img  
                alt='Motor' src='/assets/img-pages/home/motor.jpg'
                className='w-full px-[20px] sm:p-[35px] h-[194px] sm:h-[284px] rounded-sm '/>
              <div 
                className='flex flex-col items-cente  h-[65px] rounded-b-md bg-black'>
                <h4 
                  className='font-[Mulish] font-[40] text-[14px] leading-[20px] mt-[11px] text-gray-400'>
                  WEG
                </h4>
                <h3 
                  className='font-[Poppins] font-[500] text-[18px] text-white leading-[25px]'>
                  Motor
                </h3>
              </div>
            </div>

              <div 
                className='bg-white  !rounded-[10px]  shadow-[5px_5px_6px_rgba(184,184,184,0.25)]
                w-[280px]    hover:scale-[1.05] hover:shadow-lg hover:shadow-black/50 transition-all 
                md:w-[354px] md:h-[349px] ease-in' >
                <img  
                  alt='Variador de frecuencia' src='/assets/img-pages/home/variador-frecuencia.jpg'
                  className='w-full h-[194px] rounded-t-[10px] md:h-[284px] rounded-sm '/>
                <div 
                  className='flex flex-col items-cente  h-[65px] rounded-b-md bg-black'>
                  <h4 
                    className='font-[Mulish] font-[40] text-[14px] leading-[20px] mt-[11px] text-gray-400'>
                    SIEMENS
                  </h4>
                  <h3 
                    className='font-[Poppins] font-[500] text-[18px] text-white leading-[25px]'>
                    Variador de frecuencia
                  </h3>
                </div>
              </div>

            </div>
          
          <a 
            href='https://api.whatsapp.com/send?phone=51972198805&text=Hola.%20Cu%C3%A9ntame%20sobre%20tus%20servicios.%20%F0%9F%98%8A'  target="_blank">
            <button 
              className='bg-white mx-auto
              mt-[50px] w-[270px] h-[50px] text-[20px] rounded-[10px] 
              font-bold text-black
             border-white border-2 
             hover:!bg-black hover:text-white hover:border-none transition-all ease-in '>
                Consultar Precios
            </button>
          </a>
        </div>
       
      </section>

      <section 
        className='w-full pb-[100px] bg-[url("/public/assets/img-pages/home/background.jpg")] 
        bg-no-repeat bg-cover'>
        <h1 
          className='font-[Roboto] text-center font-bold  pb-[40px] pt-[50px] text-white
          text-[30px] sm:text-[45px]'>
          TRABAJOS <span className='text-[#feba12]'>REALIZADOS</span>
        </h1>

        <div 
          className='bg-black py-[15px] text-[48px] items-center justify-center grid 
          lg:grid-cols-[400px_800px] h-full gap-[50px] '>
          <div 
            className="w-[350px] sm:w-[400px] relative mx-auto bg-white rounded-lg  ">
            <img
              src='/assets/img-pages/home/works/repuesto.jpeg' alt="Servimant Industrial S.A.C"  
              className="rounded-lg h-[500px] w-[400px]" />
            <div 
              className=" text-center absolute bottom-0 backdrop-blur-[5px] backdrop-brightness-[0.5] px-[3%] 
              rounded-b-lg pt-[10px]">
              <h5 
                className="mb-2 font-[Mulish]  font-bold  text-[#feba12] text-[15px]  sm:text-[20px]">
                Servimant Industrial S.A.C
              </h5>
              <p 
                className="mb-[10px]  text-white font-[Poppins]  mx-[8%] text-[12px] sm:text-[15px]">
                Fabricación de repuestos para bomba barbotina de la planta cerámica CELIMA.
              </p>
            </div>
          </div>

          <div 
            className="w-[350px] md:w-[800px] relative mx-auto bg-white rounded-lg  ">
            <img
              src='/assets/img-pages/home/works/alineamiento.jpeg' alt="Mazal Corp" 
              className="rounded-lg md:h-[500px] w-[340px] md:w-[800px]"  />
            <div 
              className=" text-center absolute bottom-0 backdrop-blur-[5px] backdrop-brightness-[0.5] 
              px-[3%] rounded-b-lg pt-[10px] w-[350px] 
              md:w-[800px] md:h-[100px]">
              <h5 
                className="mb-2 font-[Mulish]  font-bold  text-[#feba12]
                text-[15px] sm:text-[20px] ">
                Mazal Corp
              </h5>
              <p 
                className="mb-[10px]  text-white font-[Poppins]  mx-[8%] text-[12px] sm:text-[15px]">
                Alineamiento de prensas de 120 Ton para línea de embutidos de ollas
              </p>
            </div>
          </div>
        </div>


        
      </section>

      <section className='max-w-[1880px] mx-auto mx-auto pb-[100px] sm:pb-[150px] text-center bg-white '>
        <h1 className='font-[Roboto] font-bold text-[30px] pb-[50px] pt-[50px] 
          sm:pb-[100px] sm:pt-[50px] sm:text-[45px]'>
          NUESTROS CLIENTES
        </h1>
        <div className='grid grid-cols-3  gap-x-[30px] gap-y-[25px] sm:gap-y-[50px] mx-[10%] items-center bg-white'>
          <img 
            alt='logo' src='/assets/img-pages/home/logos-empresas/siderperu.png'
            className='scale-[1.5] sm:scale-1 sm:h-[115px] mx-auto' 
          />

          <img 
            alt='logo' src='/assets/img-pages/home/logos-empresas/tupemesa.png' 
            className='scale-[1.25] sm:scale-1 sm:h-[65px] mx-auto' 
          />

          <img 
            alt='logo' src='/assets/img-pages/home/logos-empresas/trupal.png' 
            className='scale-[0.9] sm:scale-1 sm:h-[43px] mx-auto' 
          />

          <img 
            alt='logo' src='/assets/img-pages/home/logos-empresas/calaminon.png' 
            className='scale-[1.4] sm:scale-1 sm:h-[63px] mx-auto' 
          />

          <img 
            alt='logo' src='/assets/img-pages/home/logos-empresas/seiisa.png'
            className='scale-[0.8] sm:scale-1 sm:h-[70px] mx-auto' 
          />

          <img 
            alt='logo' src='/assets/img-pages/home/logos-empresas/sidel.jpg'
            className='scale-[1] sm:scale-1 sm:h-[115px] mx-auto'/>

          <img 
            alt='logo' src='/assets/img-pages/home/logos-empresas/mazal.png' 
            className='scale-[1.2] sm:scale-1 sm:h-[55px] mx-auto' 
          />

          <img 
            alt='logo' src='/assets/img-pages/home/logos-empresas/epcm.jpeg' 
            className='scale-[1] sm:scale-1 sm:h-[75px] mx-auto' 
          />

          <img 
            alt='logo' src='/assets/img-pages/home/logos-empresas/bsh.png'
            className='scale-[1.2] sm:scale-[0.5] sm:h-[150px] mx-auto flex' 
          />
          <div 
            className='grid grid-cols-2 col-span-3 items-start sm:items-center mt-[15px]  relative'>
            <img 
              alt='logo' src='/assets/img-pages/home/logos-empresas/kar.png' 
              className='scale-[0.7] sm:scale-[1.15] sm:h-[80px] mx-auto'/>
            <div>
            <img 
              alt='logo' src='/assets/img-pages/home/logos-empresas/sagant.png' 
              className='scale-[0.25] sm:scale-[1.3] sm:h-[95px] mx-auto right-[-15px] bottom-[-85px]  absolute 
               sm:right-[270px] sm:bottom-[-10px]'/>
            </div>
            
          </div>
        </div>
      </section>

    </motion.div>
    </AnimatePresence>
  )
}

export default Home
