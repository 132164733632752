import React, {useState} from 'react'
import { useEffect } from 'react'
import {Outlet} from 'react-router-dom'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import { motion, AnimatePresence } from "framer-motion"



const Main = () => {
  const [splash,setSplash] = useState(false)

  useEffect(()=>{
    const timer = setTimeout(() => {
      setSplash(!splash)
    }, 3000);
    return () => clearTimeout(timer)
    }
  ,[])

  if(!splash){
    return(
    <AnimatePresence>
       <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1  }}
          exit={{ opacity: 0}}
          transition={{ duration: 1 }}
          key="splash" 
          className='w-[100vw] bg-[#2D2828] h-[100vh] flex items-center justify-center cursor-progress absolute top-0 z-[100]'>
            <img className='' src='./icon-splash.svg'/>
        </motion.div>  
    </AnimatePresence>)
  }else{
    return (
      <div className='w-full relative '>
          <Navbar/>
          <a href='https://api.whatsapp.com/send?phone=51972198805&text=Hola.%20Cu%C3%A9ntame%20sobre%20tus%20servicios.%20%F0%9F%98%8A'  
            target="_blank"
            className='z-50 w-[6vw] fixed right-[1%] top-[83vh] invisible md:!visible'
            >
            <div 
              className='relative  before:ping before:absolute
              before:bg-[#29A71A] before:w-[70%] before:h-[70%] p-[10px] 
              before:top-[15%] before:right-[10%] before:rounded-xl '>
              <img className='!z-[60] ' src="./wsp.png" alt="" />
            </div>
          </a>
          <Outlet/>
          <Footer />
      </div>
    )

  }
  
}

export default Main