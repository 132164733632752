import React from 'react'
import { motion, AnimatePresence } from "framer-motion"


const Us = () => {

  /*
  <div className='bg-[#ffffff]  border-[2px] border-solid border-[rgba(0, 0, 0, 0.2)] mt-[4px]'>
      <p className='text-center text-[50px] font-bold'>NUESTRO EQUIPO</p>
      <div className='my-[30px] flex flex-col w-[80%] mx-auto
      md:grid md:grid-cols-3 md:my-[40px]'>
          <div className='w-[200px] mx-auto m-[15px] font-[Mulish] '>
            <div className='bg-slate-500 w-[150px] h-[150px] mx-auto rounded-full'>
              <p className='m-auto'></p>
            </div>
            <div className='text-center'>
              <p className='font-[700] text-black text-[15px]'>
                Sony Jason Allccarima
              </p>
              <p className='font-[900] text-[#7A7A7A] text-[12px]'>
                Ing. Mecánico
              </p> 
              <p className='font-[900] text-black text-[11px]'>
                CEO de ZAIN
              </p>  
            </div>
          </div>

          <div className='w-[200px] mx-auto my-[15px] font-[Mulish] '>
            <div className='bg-slate-500 w-[150px] h-[150px] mx-auto rounded-full'>
              <p className='m-auto'></p>
            </div>
            <div className='text-center'>
              <p className='font-[700] text-black text-[15px]'>
                Sony Jason Allccarima
              </p>
              <p className='font-[900] text-[#7A7A7A] text-[12px]'>
                Ing. Mecánico
              </p> 
              <p className='font-[900] text-black text-[11px]'>
                CEO de ZAIN
              </p>  
            </div>
          </div>

          <div className='w-[200px] mx-auto my-[15px]  font-[Mulish] '>
            <div className='bg-slate-500 w-[150px] h-[150px] mx-auto rounded-full'>
              <p className='m-auto'></p>
            </div>
            <div className='text-center'>
              <p className='font-[700] text-black text-[15px]'>
                Sony Jason Allccarima
              </p>
              <p className='font-[900] text-[#7A7A7A] text-[12px]'>
                Ing. Mecánico
              </p> 
              <p className='font-[900] text-black text-[11px]'>
                CEO de ZAIN
              </p>  
            </div>
          </div>

          <div className='w-[200px] mx-auto my-[15px]  font-[Mulish] '>
            <div className='bg-slate-500 w-[150px] h-[150px] mx-auto rounded-full'>
              <p className='m-auto'></p>
            </div>
            <div className='text-center'>
              <p className='font-[700] text-black text-[15px]'>
                Sony Jason Allccarima
              </p>
              <p className='font-[900] text-[#7A7A7A] text-[12px]'>
                Ing. Mecánico
              </p> 
              <p className='font-[900] text-black text-[11px]'>
                CEO de ZAIN
              </p>  
            </div>
          </div>

          <div className='w-[200px] mx-auto my-[15px] font-[Mulish] '>
            <div className='bg-slate-500 w-[150px] h-[150px] mx-auto rounded-full'>
              <p className='m-auto'></p>
            </div>
            <div className='text-center'>
              <p className='font-[700] text-black text-[15px]'>
                Sony Jason Allccarima
              </p>
              <p className='font-[900] text-[#7A7A7A] text-[12px]'>
                Ing. Mecánico
              </p> 
              <p className='font-[900] text-black text-[11px]'>
                CEO de ZAIN
              </p>  
            </div>
          </div>

          <div className='w-[200px] mx-auto m-[15px] font-[Mulish] '>
            <div className='bg-slate-500 w-[150px] h-[150px] mx-auto rounded-full'>
              <p className='m-auto'></p>
            </div>
            <div className='text-center'>
              <p className='font-[700] text-black text-[15px]'>
                Sony Jason Allccarima
              </p>
              <p className='font-[900] text-[#7A7A7A] text-[12px]'>
                Ing. Mecánico
              </p> 
              <p className='font-[900] text-black text-[11px]'>
                CEO de ZAIN
              </p>  
            </div>
          </div>
      </div>
    </div>*/

  return (
    <AnimatePresence>     
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1  }}
      exit={{ opacity: 0}}
      transition={{ duration: 2 }}
      className='w-full h-full bg-white'>
      <div 
        className=' mx-auto rounded-b-md w-full relative bg-[url("/public/assets/img-pages/nosotros/fondo.jpg")] bg-cover 
        bg-no-repeat bg-center h-[1350px] md:h-[700px]'>
        <div
          className='absolute  w-[800px] h-[500px] top-[22%]
          md:top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]'>
          <div 
            className='grid grid-cols-1 gap-y-[50px]  items-center justify-items-center md:grid-cols-3'>
            <div>
              <h5 
                className='text-center text-[Roboto] text-[#feba12] font-bold text-[24px]  sm:text-[30px] '>
                HONESTIDAD
              </h5>
              <img className='mx-auto w-[150px] mt-[8%] md:w-auto' src='./assets/img-pages/nosotros/honestidad.png' alt='valores'/>
            </div>
            <div>
              <h5 
                className='text-center text-[Roboto] text-[#feba12] font-bold text-[24px]  sm:text-[30px] '>
                INNOVACIÓN
              </h5>
              <img className='mx-auto w-[150px] mt-[8%] md:w-auto' src='./assets/img-pages/nosotros/innovacion.png' alt='valores'/>
            </div>
            <div>
              <h5 
                className='text-center text-[Roboto] text-[#feba12] font-bold text-[24px]  sm:text-[30px] '>
                EXIGENCIA
              </h5>
              <img className='mx-auto w-[150px] mt-[8%] md:w-auto' src='./assets/img-pages/nosotros/exigencia.png' alt='valores'/>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 justify-items-center py-[50px] '>
            <div>
              <h5 
                className='text-center text-[Roboto] text-[#feba12] font-bold text-[24px]  sm:text-[30px] '>
                PASIÓN
              </h5>
              <img className='mx-auto w-[150px] mt-[8%] md:w-auto' src='./assets/img-pages/nosotros/pasion.png' alt='valores'/>
            </div>
            <div className='mt-[50px] md:mt-0'>
              <h5 
                className='text-center text-[Roboto] text-[#feba12] font-bold text-[24px]  sm:text-[30px] '>
                RESPONSABILIDAD
              </h5>
              <img className='mx-auto w-[150px] mt-[8%] md:w-auto' src='./assets/img-pages/nosotros/responsabilidad.png' alt='valores'/>
            </div>
          </div>
        </div>
      </div>

      <div 
        className=' grid py-[5px] gap-[5px] 
        sm:gap-[15px]  mx-auto  text-roboto px-[2%]
        md:py-[25px] grid-cols-1 lg:grid-cols-2 '>
        <div 
          className='bg-[#FEBA12] rounded-md h-[200px] border-[2px] border-solid border-[#FEBA12] 
          text-center  mt-[4px] flex flex-col
          md:h-[350px]'>
          <div 
            className='m-auto w-[80%] pb-10'>
            <p 
              className='font-[700] text-white/90 text-[25px] sm:text-[45px]'>MISIÓN</p>
            <p 
              className=' mt-[10px] font-[#5A5A5A] font-[400] text-[15px]
              sm:text-[18px]'>
              Satisfacer los requerimientos tecnológicos de nuestros clientes mediante una atención
              personalizada. Crear una cultura de trabajo en equipo e impulsar el desarrollo personal y
              profesional de nuestros colaboradores.
            </p>
          </div> 
        </div>

        <div 
          className='bg-black/95 rounded-md h-[200px] border-[2px] border-solid border-black 
          mt-[4px] text-center flex flex-col
          md:h-[350px]'>
          <div 
            className='m-auto w-[80%] pb-10'>
            <p 
              className='font-[700] text-white text-[25px] sm:text-[30px] md:text-[45px]'>VISIÓN</p>
            <p 
              className=' mt-[10px] text-[#FFF3F3] font-[#5A5A5A] font-[400] text-[15px] sm:text-[18px]'>
              Ser una empresa tecnológica y humana, referente en el campo de la innovación, competitiva
              en el mercado mundial y cuna de conocimientos.
            </p>
          </div> 
        </div>
      </div>

    
    </motion.div>
    </AnimatePresence> 
  )
}

export default Us